import React, { createContext, useState, useRef, useCallback } from 'react';
import { FullpageApi } from '@fullpage/react-fullpage';

type ProviderProps = { children: React.ReactNode };

type SidebarContextType = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initFullpageApi: (fullpageApi: FullpageApi) => void;
  onSectionSelect: (
    sectionAnchor: SectionAnchor | SectionSlideAnchor,
    silent?: boolean,
  ) => void;
};

const initialState: SidebarContextType = {
  open: false,
  setOpen: () => {},
  initFullpageApi: () => {},
  onSectionSelect: () => {},
};

export const SidebarContext = createContext<SidebarContextType>(initialState);

export const SidebarProvider: React.FC<ProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(initialState.open);

  const fullpageApiRef = useRef<FullpageApi>();

  const initFullpageApi = useCallback((fullpageApi: FullpageApi) => {
    fullpageApiRef.current = fullpageApi;
  }, []);

  const onSectionSelect = useCallback(
    async (
      sectionAnchor: SectionAnchor | SectionSlideAnchor,
      silent?: boolean,
    ) => {
      const anchorStringSplit = sectionAnchor.split('/');
      let sectionStr = anchorStringSplit[0];
      let slideStr = anchorStringSplit[1];

      setOpen(false);

      await new Promise((res) => setTimeout(res, 300));

      if ((window.innerWidth < 960 || window.innerHeight < 480) && slideStr) {
        sectionStr = slideStr;
        slideStr = '';
      }

      if (silent) {
        fullpageApiRef.current?.silentMoveTo(sectionStr, slideStr || 0);
      } else {
        fullpageApiRef.current?.moveTo(sectionStr, slideStr || 0);
      }
    },
    [],
  );

  return (
    <SidebarContext.Provider
      value={{
        open,
        setOpen,
        initFullpageApi,
        onSectionSelect,
      }}>
      {children}
    </SidebarContext.Provider>
  );
};
