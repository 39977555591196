import React from 'react';
import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { colors, preset } from 'styles';
import { PageDataProvider } from 'context';

type Props = { children: React.ReactNode; location: Location };

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: colors.PRIMARY,
          backgroundColor: colors.WHITE,
        },
        svg: {
          maxWidth: '100%',
          maxHeight: '100%',
        },
        p: { marginTop: 0, marginBottom: preset.spacing(2) },
        a: { color: colors.PRIMARY },
        '.full-height': {
          height: 'inherit',
        },
        '.fade-enter': {
          opacity: 0,
        },
        '.fade-exit': {
          opacity: 1,
        },
        '.fade-enter-active': {
          opacity: 1,
          transition: preset.TRANSITION,
        },
        '.fade-exit-active': {
          opacity: 0,
          transition: preset.TRANSITION,
        },
        'h6.MuiPickersToolbarText-toolbarTxt': {
          fontFamily: 'Albra',
          fontSize: '1rem',
          lineHeight: 1.75,
          textDecoration: 'none',
        },
        '::-webkit-scrollbar': {
          width: 2,
        },
        '::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '::-webkit-scrollbar-thumb': {
          background: colors.PRIMARY,
        },
      },
    },
  },
  palette: {
    primary: { main: colors.PRIMARY },
  },
  typography: {
    fontFamily: 'Albra',
    h3: {
      fontFamily: 'PalmerLakePrint',
      fontSize: '2.3rem',
      textDecoration: 'underline',
      [preset.breakpoints.xl]: {
        fontSize: '2.5rem',
      },
    },
    subtitle1: {
      fontFamily: 'PalmerLakePrint',
      fontSize: '2rem',
      textDecoration: 'underline',
      lineHeight: 0.5,
      [preset.breakpoints.xs]: {
        fontSize: '2.1rem',
      },
      [preset.breakpoints.xl]: {
        fontSize: '2.2rem',
      },
    },
  },
});

const MUIProvider: React.FC<Props> = ({ children }) => {
  return (
    <PageDataProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StylesProvider injectFirst>{children}</StylesProvider>
      </ThemeProvider>
    </PageDataProvider>
  );
};

export default MUIProvider;
