export const spacing = (factor: number) => 8 * factor;

export const TRANSITION = 'all 225ms cubic-bezier(0.0, 0, 0.2, 1)';

export const SIDEBAR_WIDTH = { lg: 200, xl: 240 };

export const breakpoints = {
  xs: `@media (min-width: 375px)`,
  sm: `@media (min-width: 600px)`,
  md: `@media (min-width: 960px)`,
  lg: `@media (min-width: 1280px)`,
  xl: `@media (min-width: 1440px)`,
  xxl: `@media (min-width: 1920px)`,
  h: `@media (min-width: 960px) and (min-height: 1080px)`,
  vh: `@media (min-width: 960px) and (min-height: 1200px)`,
};
