import React, { createContext, useState, useEffect, useCallback } from 'react';
import firebase from 'gatsby-plugin-firebase';

type ProviderProps = { children: React.ReactNode };

type PageDataContextType = {
  getPageHTML: (pageTitle: CMSPageTitle) => string;
  isPagesLoaded: boolean;
};

const initialState: PageDataContextType = {
  getPageHTML: () => '',
  isPagesLoaded: false,
};

export const PageDataContext = createContext<PageDataContextType>(initialState);

export const PageDataProvider: React.FC<ProviderProps> = ({ children }) => {
  const [pages, setPages] = useState<CMSPage[]>([]);

  useEffect(() => {
    firebase.analytics();
  }, []);

  useEffect(() => {
    const fetchCMSPages = async () => {
      const getPages = firebase.functions().httpsCallable('getPages');
      const { data } = await getPages();
      setPages(data);
    };

    fetchCMSPages();
  }, []);

  const getPageHTML = useCallback(
    (pageTitle: CMSPageTitle): string => {
      if (pages.length === 0) {
        return '';
      }
      const matchedPage = pages.find((page) =>
        page.title.toUpperCase().includes(pageTitle.toUpperCase()),
      );
      if (
        matchedPage &&
        pageTitle === 'POPUP' &&
        matchedPage.status === 'draft'
      ) {
        return '';
      }
      return matchedPage?.body_html || '';
    },
    [pages],
  );

  return (
    <PageDataContext.Provider
      value={{ getPageHTML, isPagesLoaded: pages.length > 0 }}>
      {children}
    </PageDataContext.Provider>
  );
};
