import 'firebase/analytics';
import 'firebase/functions';
import React from 'react';
import MUIProvider from './src/components/MUIProvider';

require('./src/styles/typography.css');

export const wrapRootElement = ({ element }) => {
  return <MUIProvider>{element}</MUIProvider>;
};
